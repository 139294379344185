body {
  margin: 0;
  font-family: 'Outfit', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.main:root {
  --first-color: #750175;
}

.second:root {
  --first-color: #7c928f;
}

.third:root {
  --first-color: #0000e4;
}

.fourth:root {
  --first-color: #e4a700;
}

.fifth:root {
  --first-color: #645162;
}

.sixth:root {
  --first-color: #17bf33;
}

.seventh:root {
  --first-color: #0da8a1;
}

.eighth:root {
  --first-color: #bf0808;
}
