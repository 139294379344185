.backgroundOne {
  background-color: var(--first-color);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
  z-index: 9990;
}

.backgroundOneHidden {
  transform: translateY(100%);
}

.backgroundTwo {
  background-color: white;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateY(0);
  transition: transform 400ms ease-in-out;
  z-index: 9995;
}

.backgroundTwoHidden {
  transform: translateY(100%);
}

.container {
  height: 100vh;
  width: 100%;
  position: relative;
  transform: translateY(0);
  transition: transform 400ms ease-in-out;
  z-index: 9999;
  background-color: white;
  overflow: auto;
}

.containerHidden {
  transform: translateY(100%);
}
/* 
.content .card {
  transition: box-shadow 200ms, border-color 200ms;
}

.content .cardHover {
  transition: box-shadow 200ms, border-color 200ms;
} */

/* .backgroundOne {
  background-color: var(--first-color);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  transition: top 300ms ease-in-out;
  z-index: 9990;
}

.backgroundOneHidden {
  background-color: var(--first-color);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 100vh;
  transition: top 300ms ease-in-out;
  z-index: 9990;
}

.backgroundTwo {
  background-color: white;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  transition: top 400ms ease-in-out;
  z-index: 9995;
}

.backgroundTwoHidden {
  background-color: white;
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 600vh;
  transition: top 400ms ease-in-out;
  z-index: 9995;
}

.container {
  height: 100vh;
  width: 100%;
  position: relative;
  top: 0;
  transition: 400ms;
  z-index: 9999;
  background-color: white;
  overflow: auto;
}


.containerHidden {
  position: relative;
  top: 100vh;
  z-index: 9999;
  transition: 400ms;
} */


.container::after {
  position: fixed;
  content: '';
  background-image: url(../../../assets/image/blackbg.png);
  background-repeat: repeat;
  background-size: cover;
  top: 0;
  bottom:0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.01;
  border: 1px solid red;
}

.close {
  position: absolute;
  top: 60px;
  left: 60px;
  cursor: pointer;
  color: black;
  transition: color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.close:hover {
  color: var(--first-color);
  transition: color 200ms;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 84px;
}

.title h1 {
  font-size: 100px;
  margin: 0;
  font-weight: 800;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 108px;
}

.title h4 {
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  margin: 0;
}

.content {
  margin-block: 48px;
  color: var(--first-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 60px;
  flex-wrap: wrap;
}

.content .card {
  position: relative;
  flex: 1;
  min-width: 440px;
  max-width: 640px;
  height: 240px;
  box-sizing: border-box;
  border: 3px solid transparent;
  border-radius: 8px;
  margin: 16px;
  overflow: hidden;
  box-shadow: inset 0 0 10px rgb(204, 159, 159);
}

.content .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.content .card h1 {
  position: absolute;
  font-size: 20px;
  opacity: 0.7;
  top: -4px;
  left: 12px;
  transition: left 300ms ease-in;
  background-color: white;
  border-radius: 4px;
  padding-inline: 12px;
  padding-block: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .card .info {
  position: absolute;
  right: -45%;
  top: 0;
  height: 100%;
  width: 45%;
  padding: 24px;
  box-sizing: border-box;
  background-color: white;
  transition: right 300ms ease-out;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content .cardHover {
  position: relative;
  flex: 1;
  min-width: 440px;
  max-width: 640px;
  height: 240px;
  box-sizing: border-box;
  border: 3px solid var(--first-color);
  border-radius: 8px;
  margin: 16px;
  overflow: hidden;
}

.content .cardHover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.4;
  transition: opacity 300ms ease-out;
}

.content .cardHover h1 {
  position: absolute;
  font-size: 20px;
  top: -4px;
  left: -340px;
  transition: left 300ms ease-in;
  background-color: white !important;
  border-radius: 4px;
  padding-inline: 12px;
  padding-block: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content .cardHover .infoHover {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 45%;
  padding: 24px;
  box-sizing: border-box;
  background-color: white;
  transition: right 300ms ease-out;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}



.infoModal {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  backdrop-filter: blur(5px);
}

.infoCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 348px;
  padding: 24px 12px;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
}
.infoTitle {
  font-size: 20px;
  font-weight: 500;
}
.infoContent {
  text-align: center;
  padding-inline: 24px;
}

.infoClose {
  position: relative;
  width: 80%;
  height: 48px;
  margin: 0;
  border: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms;
  overflow: hidden;
  font-family: 'Outfit', sans-serif;
  background-color: var(--first-color);
  color: white;
}

.infoClose::after {
  content: 'Close';
  position: absolute;
  left: 0;
  top: 48px;
  width: 100%;
  height: 48px;
  background-color: #efefef;
  transition: 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.infoClose:hover::after {
  top: 0;
  transition: 300ms;
}

.infoClose:hover {
  padding-bottom: 46px;
  transition: 400ms;
}

.infoClose:active {
  transform: scale(0.95);
  transition: 200ms;
}

.direction {
  position: relative;
  width: 100%;
  height: 52px;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms;
  overflow: hidden;
  font-family: 'Outfit', sans-serif;
  background-color: var(--first-color);
  color: white;
}

.direction::after {
  content: 'Visit';
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  height: 52px;
  background-color: #efefef;
  transition: 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.direction:hover::after {
  top: 0;
  transition: 300ms;
}

.direction:hover {
  padding-bottom: 50px;
  transition: 400ms;
}

.direction:active {
  transform: scale(0.95);
  transition: 200ms;
}

@media (max-width: 768px) {
  .close {
    top: 20px;
    left: 12px;
  }
  .close span:nth-child(2) {
    display: none;
  }
}

@media (max-width: 576px) {
  .title h1 {
    font-size: 80px;
  }

  .title h4 {
    font-size: 16px;
  }

  .content .card {
    min-width: 320px !important;
  }

  .content .cardHover {
    min-width: 320px !important;
  }

  .content .cardHover .infoHover {
    width: 65%;
  }
}
