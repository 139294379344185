.container {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 60;
}

.container .content p {
  margin: 0;
  font-weight: 300;
}

.container .content p a {
  margin: 0;
  font-weight: 300;
  text-decoration: none;
  color: black;
}

.container .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.container .content .social {
  display: flex;
  gap: 8px;
}

.container .content .social a {
  text-decoration: none;
  color: black;
}

.container .content .social a span {
  transition: 200ms;
  cursor: pointer;
}

.container .content .social a span:hover {
  transition: 200ms;
  color: var(--first-color);
}


@media (max-width: 576px) {
  .container {
  top: 12px;
  left: 12px;
  }
}