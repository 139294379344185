.container {
  position: absolute;
  bottom: -650px;
  /* height: 460px; */
  height: max-content;
  width: 100%;
  background-color: white;
  padding-block: 72px;
  padding-inline: 140px;
  box-sizing: border-box;
  transition: bottom 300ms ease-out;
}

.container_visible {
  position: absolute;
  bottom: 0;
  min-height: 460px;
  height: max-content;
  width: 100%;
  background-color: white;
  padding-block: 72px;
  padding-inline: 140px;
  box-sizing: border-box;
  transition: bottom 600ms ease-out;
}
.container_visible::after {
  position: absolute;
  content: '';
  background-image: url(../../../assets/image/blackbg.png);
  background-position: left;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.02;
}

.background {
  position: absolute;
  bottom: -400px;
  height: 400px;
  width: 100%;
  background-color: black;
  opacity: 0.7;
  transition: 600ms ease-in-out;
}

.background_visible {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  transition: 300ms ease-in-out;
  opacity: 0.7;
}

.close_button {
  position: absolute;
  right: 48px;
  top: -24px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 800;
  transform: rotate(135deg);
  background-color: white;
  cursor: pointer;
  z-index: 1;
}

.close_button span {
  transform: rotate(45deg);
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  gap: 72px;
  z-index: 1;
  bottom: 0;
  min-height: 372px;
}

.content .image_part {
  position: relative;
  display: flex;
  justify-content: center;
  border: 4px solid var(--first-color);
  height: 300px;
  width: 360px;
}
.content .image_part .image_holder {
  position: absolute;
  top: -38px;
  border-inline: 6px solid white;
  background-image: url(../../../assets/image/1684620138660.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 380px;
  width: 92%;
}

.content .info_part {
  height: 100%;
  width: 72%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content .info_part .name {
  font-size: 44px;
  font-weight: 800;
}

.content .info_part .info {
  display: flex;
  flex-wrap: wrap;
  gap: 4x;
}

.content .info_part .info div {
  display: flex;
  flex: 1;
  min-width: 240px;
  border: 1px solid transparent;
}

.content .info_part .info p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content .info_part .info p span {
  color: #1f1f1f83;
  margin-right: 4px;
}

.content .info_part .info p span:nth-child(3) {
  color: #1f1f1f83;
  margin-left: 6px;
  margin-top: 3px;
}

.content .info_part .info p a {
  color: var(--first-color);
  text-decoration: none;
  font-weight: 600;
}

.content .info_part .skills {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  height: max-content;
}

.content .info_part .skills div {
  display: flex;
  flex: 1;
  max-width: 120px;
  min-width: 120px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column-reverse;
  gap: 4px;
  height: 72px;
}

.content .info_part .skills div img {
  border-radius: 10px;
}

@media (max-width: 1280px) {
  .container,
  .container_visible {
    padding-inline: 40px;
  }
  .content .info_part .skills {
    gap: 4px;
  }
  .content {
    gap: 40px;
  }
  .content .info_part {
    gap: 4px;
  }
  .content .info_part .skills div {
    max-width: 92px;
    min-width: 92px;
  }
}

@media (max-width: 1024px) {
  .content {
    flex-direction: column;
    gap: 8px;
  }
  .content .image_part {
    position: initial;
    overflow: hidden;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    border: 2px solid var(--first-color);
    padding: 4px;
  }
  .content .image_part .image_holder {
    border: 0px solid white;
    position: initial;
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .content .info_part {
    flex-direction: column;
    align-items: center;
    height: 320px;
    width: 100%;
  }

  .content .info_part .info {
    justify-content: center;
    margin-left: 0px;
  }

  .content .info_part .name {
    font-size: 32px;
    text-align: center;
  }

  .content .info_part .info div {
    max-width: 220px;
    justify-content: center;
  }

  .content .info_part .skills {
    justify-content: center;
    margin-left: 0px;
  }
  .content .info_part .skills div {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .content {
    overflow: auto;
  }
}

@media (max-width: 576px) {
  .container_visible {
    padding-block: 44px;
  }

  .content .info_part {
    flex-direction: column;
    align-items: center;
    height: 100px;
    width: 100%;
  }
}
