.container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.container .text {
  position: absolute;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
  height: max-content;
  width: 100%;
}

.container .text::before {
  content: '';
  position: absolute;
  left: 30px;
  top: calc(50% - 66px);
  width: 132px;
  height: 132px;
  background-image: url(../../assets/image/shape1.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.5;
}
.container .text::after {
  content: '';
  position: absolute;
  right: -50px;
  top: calc(50% - 74px);
  width: 148px;
  height: 148px;
  background-image: url(../../assets/image/shape2.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.container .text h1 {
  margin: 0;
  text-align: center;
  letter-spacing: 3px;
  font-weight: bolder;
  user-select: none;
}
.container .text p {
  text-align: center;
  font-size: 18px;
  max-width: 400px;
  margin: auto;
}

.container .text h1:nth-child(1) {
  font-size: 230px;
  color: #f4f4f4;
}

.container .text h1:nth-child(2) {
  margin-top: -200px;
  font-size: 180px;
}

.container .text h1:nth-child(3) {
  margin-top: -76px;
  font-size: 180px;
}

@media (max-width: 1021px) {
  .container .text p {
    font-size: 14px;
  }

  .container .text h1:nth-child(1) {
    font-size: 180px;
  }

  .container .text h1:nth-child(2) {
    margin-top: -150px;
    font-size: 130px;
  }
  .container .text h1:nth-child(3) {
    margin-top: -66px;
    font-size: 130px;
  }
}

@media (max-width: 768px) {
  .container .text::before {
    width: 82px;
    height: 82px;
  }
  .container .text::after {
    width: 98px;
    height: 98px;
  }

  .container .text h1:nth-child(1) {
    font-size: 140px;
  }

  .container .text h1:nth-child(2) {
    margin-top: -100px;
    font-size: 90px;
  }
  .container .text h1:nth-child(3) {
    margin-top: -46px;
    font-size: 90px;
  }
}

@media (max-width: 576px) {
  .container .text::before {
    display: none;
  }
  .container .text::after {
    display: none;
  }

  .container .text p {
    padding-inline: 52px;
  }

  .container .text h1:nth-child(1) {
    font-size: 84px;
  }

  .container .text h1:nth-child(2) {
    margin-top: -60px;
    font-size: 50px;
  }
  .container .text h1:nth-child(3) {
    margin-top: -26px;
    font-size: 50px;
  }
}
