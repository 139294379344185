.container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  box-sizing: border-box;
  padding: 24px;
}

.container .start {
  color: #333;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  gap: 18px;
  width: 208px;
}
.container .start span {
  position: relative;
}

.container .start span::before {
  content: '';
  position: absolute;
  left: -12px;
  bottom: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--first-color);
}

.container .middle {
  background-image: url(../../assets/image/3.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 40px;
  box-sizing: border-box;
  width: 208px;
  height: 100px;
  opacity: 0.5;
}

.container .end {
  color: #333;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 18px;
  width: 208px;
  height: 100%;
}

.container .end .about {
  position: relative;
  width: 100%;
  height: 52px;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms;
  overflow: hidden;
  font-family: 'Outfit', sans-serif;
}

.container .end .about::after {
  content: 'About Me';
  position: absolute;
  left: 0;
  top: 52px;
  background-color: var(--first-color);
  width: 100%;
  height: 52px;
  transition: 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.container .end .about:hover::after {
  top: 0;
  transition: 300ms;
}

.container .end .about:hover {
  padding-bottom: 50px;
  transition: 400ms;
}

.container .end .about:active {
  transform: scale(0.95);
  transition: 200ms;
}

.container .end .portfolio {
  position: relative;
  width: 100%;
  height: 52px;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  cursor: pointer;
  transition: 200ms;
  overflow: hidden;
  font-family: 'Outfit', sans-serif;
  background-color: var(--first-color);
  color: white;
}

.container .end .portfolio::after {
  content: 'My Portfolio';
  position: absolute;
  left: 0;
  top: 52px;
  width: 100%;
  height: 52px;
  background-color: #efefef;
  transition: 400ms;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.container .end .portfolio:hover::after {
  top: 0;
  transition: 300ms;
}

.container .end .portfolio:hover {
  padding-bottom: 50px;
  transition: 400ms;
}

.container .end .portfolio:active {
  transform: scale(0.95);
  transition: 200ms;
}

@media (max-width: 1021px) {
  .container .middle {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 1021px) {
  .container .middle {
    display: none;
  }
  .container {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 140px;
    gap: 16px;
  }
}
