.container {
  position: absolute;
  border: 1px solid #78787895;
  left: -242px;
  top: 180px;
  width: 242px;
  height: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 16px;
  box-sizing: border-box;
  z-index: 99;
  transition: 300ms ease-in-out;
  gap: 4px;
}

.container_visible {
  position: absolute;
  border: 1px solid #78787895;
  left: -12px;
  top: 180px;
  width: 242px;
  height: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  padding: 16px;
  box-sizing: border-box;
  z-index: 99;
  transition: 300ms ease-in-out;
  gap: 4px;
}

.after {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #78787895;
  border-right: 1px solid #78787895;
  border-bottom: 1px solid #78787895;
  border-radius: 2px;
  width: 56px;
  height: 56px;
  right: -57px;
  top: -1px;
  background-color: white;
  cursor: pointer;
}

.after div {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: return 3s linear infinite;
  padding-top: 4px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.button_list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 4px;
}
.button_list .color_button {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.button_list .color_button:active {
  transform: scale(0.90);
  transition: 200ms;
}

@keyframes return {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
